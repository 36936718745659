<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import customerShow from "./show/show.vue";
import CustomTable from "@/components/tables/custom-table.vue";
import Status from "@/helpers/general/status";

/**
 * Customers Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    customerShow,
    CustomTable,
  },
  data() {
    return {
      showModalId:'card-application-show-modal',
      pageHeader:{
        title: "Solicitudes de carné",
        breadcrumbs: [
          {
            text: "Solicitudes de carné",
            href: "/card-applications",
            active: true
          }
        ],
      },
      customer_status: Status.getList('customers'),
      table:{
        url:'/card-applications',
        params: {
          'with_trashed': 1,
        },
        fields:[
          { key: "id", label: "Cod", sortable: true },
          { key: "name", label: "Nombre", sortable: true },
          { key: "email", label: "Email", sortable: true },
          { key: "gender", label: "Género", sortable: true },
          { key: "birthdate", label: "Fecha de nacimiento", sortable: true },
          { key: "created_at", label: "Fecha de solicitud", sortable: true },
          { key: "shipping_type", label: "Tipo de envío", sortable: true },
          { key: "status", label: "Estado", sortable: true },
        ],
        formatted_fields:[
          'status',
          'gender',
          'shipping_type',
        ]
      },
      formSelectedItemId:null,   
      showSelectedItemId:null,
      clone:false
    };
  },
  methods: {
    showViewModal(item) {
      this.showSelectedItemId = item?.id || null;
      this.$bvModal.show(this.showModalId);
    },
    onSuccess(){
      this.$refs.customerTable.refresh();
    },
    formatter(field, item){
      switch(field){
        case 'gender':
          return Status.getStatus({itemStatus: item?.gender, type: 'genders'});
        case 'shipping_type':
          return Status.getStatus({itemStatus: item?.shipping_type, type: 'shipping_types'});
        case 'status':
          return Status.getStatus({itemStatus: item?.status, type: 'card_application_statuses', html: true});
        default:
          return '';
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="pageHeader?.title" :items="pageHeader?.breadcrumbs" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <customerShow :modal-id="showModalId" :id="showSelectedItemId"></customerShow>
            <CustomTable
              :url="table.url"
              :fields="table.fields"
              :formatted-fields="table.formatted_fields"
              :formatter="formatter"
              :params="table.params"
              ref="customerTable"
              :show-view="true"
              :show-edit="false"
              :showSoftDelete="false"
              @show="showViewModal"
              :show-modal-id="showModalId"
            >
              <template v-slot:filters="{ filters }">
                <div class="row">
                    <div class="form-group col-12 col-lg-4 col-sm-6">
                        <label for="name">Nombre</label>
                        <input id="name" v-model="filters.name" type="text" class="form-control" placeholder="Filtrar por nombre"/>
                    </div>
                    <div class="form-group col-12 col-lg-4 col-sm-6">
                        <label for="email">Email</label>
                        <input id="email" v-model="filters.email" type="text" class="form-control" placeholder="Filtrar por email"/>
                    </div>
                </div> 
              </template>
            </CustomTable>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>