const status = {
    products:[
        {
            id:'draft',
            label:'Borrador'
        },
        {
            id:'pending',
            label:'Pendiente'
        },
        {
            id:'private',
            label:'Privado'
        },
        {
            id:'publish',
            label:'Publicado'
        }
    ],
    orders:[
        {
            id: 'pending',
            label: 'Pendiente',
            class: 'badge badge-soft-warning font-size-11'
        },
        {
            id: 'processing',
            label: 'Procesando',
            class: 'badge badge-soft-warning font-size-11'
        },
        {
            id: 'on-hold',
            label: 'En espera',
            class: 'badge badge-soft-warning font-size-11'
        },
        {
            id: 'completed',
            label: 'Completado',
            class: 'badge badge-soft-success font-size-11'
        },
        {
            id: 'cancelled',
            label: 'Cancelado',
            class: 'badge badge-soft-danger font-size-11'
        },
        {
            id: 'refunded',
            label: 'Reembolsado',
            class: 'badge badge-soft-danger font-size-11'
        },
        {
            id: 'failed',
            label: 'Fallido',
            class: 'badge badge-soft-danger font-size-11'
        }
    ],
    product_types:[
        {
            id:'simple',
            label:'Producto simple'
        },
        {
            id:'grouped',
            label:'Producto agrupado'
        },
        {
            id:'external',
            label:'Producto externo'
        },
        {
            id:'variable',
            label:'Producto variable'
        }
    ],
    product_tax_status:[
        {
            id:'taxable',
            label:'Sujeto a impuestos'
        },
        {
            id:'shipping',
            label:'Envío solamente'
        },
        {
            id:'none',
            label:'Ninguno'
        }
    ],
    stock_status:[
        {
            id:'instock',
            label:'Hay existencias'
        },
        {
            id:'outofstock',
            label:'Sin existencias'
        },
        {
            id:'onbackorder',
            label:'Se puede reservar'
        }
    ],
    backorder_status:[
        {
            id:'no',
            label:'No permitir'
        },
        {
            id:'notify',
            label:'Permitir, pero se avisará al cliente'
        },
        {
            id:'yes',
            label:'Permitir'
        }
    ],
    genders:[
        {
            id:'female',
            label:'Femenino'
        },
        {
            id:'male',
            label:'Masculino'
        },
        {
            id:'other',
            label:'Otro'
        }
    ],
    catalog_visibilities:[
        {
            id:'visible',
            label:'En la tienda y en los resultados de búsqueda'
        },
        {
            id:'catalog',
            label:'Solo en la tienda'
        },
        {
            id:'search',
            label:'Solo en los resultados de búsqueda'
        },
        {
            id:'hidden',
            label:'Oculto'
        }
    ],
    integration_product_import_status:[
        {
            id:0,
            label:'Pendiente'
        },
        {
            id:1,
            label:'Leyendo productos'
        },
        {
            id:2,
            label:'Guardando productos'
        },
        {
            id:3,
            label:'Sincronizando con WooCommerce'
        },
        {
            id:4,
            label:'Completado'
        },
        {
            id:5,
            label:'Sincronización fallida'
        },
    ],
    order_integration_status:[
        {
            id:'processing',
            label:'En proceso',
            class: 'badge badge-soft-warning font-size-11'
        },
        {
            id:'completed',
            label:'Completado',
            class: 'badge badge-soft-success font-size-11'
        },
        {
            id:'cancelled',
            label:'Cancelado',
            class: 'badge badge-soft-danger font-size-11'
        },
        {
            id:'failed',
            label:'Fallido',
            class: 'badge badge-soft-danger font-size-11'
        },
    ],
    coupon_discount_types:[
        {
            id:"fixed_cart",
            label:"Descuento fijo en el carrito"
        },
        {
            id:"percent",
            label:"Descuento en porcentaje"
        },
        {
            id:"fixed_product",
            label:"Descuento fijo por producto"
        },      
    ],
    card_application_statuses:[
        {
            id:"processing",
            label:"En proceso",
            class: 'badge badge-soft-warning font-size-11'
        },
        {
            id:"completed",
            label:"Completado",
            class: 'badge badge-soft-success font-size-11'
        },
        {
            id:"cancelled",
            label:"Cancelado",
            class: 'badge badge-soft-danger font-size-11'
        },      
    ],
    shipping_types:[
        {
            id:"online",
            label:"Online"
        },
        {
            id:"carne_joven",
            label:"Carné Joven"
        },
        {
            id:"ibercaja",
            label:"Ibercaja"
        },      
    ],
}

export default {
    getStatus({itemStatus, type, html = false}) {
        const item = status[type]?.find(({id}) => id == itemStatus);
        return (html) ? `<span class="${item?.class}">${item?.label}</span>` : item?.label;
    },
    getList(type) {
        return status[type];
    }
};