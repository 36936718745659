<script>
import api from '@/helpers/apirest/api';
import Modal from "@/components/modals/modal-form.vue";
import Status from "@/helpers/general/status";
import orderShow from "@/views/pages/orders/show/show.vue";

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        id: Number
    },
    data() {
        return {
            cardApplication:null,
            showModalId:'order-show-modal',
            cardApplicationFormModalId:'card-application-form-modal-show',
            failedRequestModalId:'failed-request-modal-show',
            cardApplicationIntegrationStatus: Status,
            showSelectedItemId:null
        }
    },
    components: {
        Modal,
        orderShow,
    },
    computed: {
        modalTilte(){
            return `Solicitud de carné #${(this.cardApplication?.id || '')}`;
        },
        status(){
            return Status.getStatus({itemStatus: this?.cardApplication?.status, type: 'card_application_statuses', html: true});
        },
        gender(){
            return Status.getStatus({itemStatus: this?.cardApplication?.gender, type: 'genders'});
        },
        shippingType(){
            return Status.getStatus({itemStatus: this?.cardApplication?.shipping_type, type: 'shipping_types'});
        },
        orderStatus(){
            return Status.getStatus({itemStatus: this?.cardApplication?.order?.status, type: 'orders', html: true});
        },
    },
    methods: {
        async getData(){
            if (this.id) {
                const response = await api.get({
                    url: `card-applications/${this.id}`,
                    config: {
                        withLoading: true
                    }
                });
                this.cardApplication = response?.data?.data;
            }
        },
        showViewModal(item) {
            this.showSelectedItemId = item?.id || null;
            this.$bvModal.show(this.showModalId);
        },
        shown() {
            this.getData();            
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        onSuccess(){            
            this.getData(); 
        },
    }
};
</script>

<template>
    <div>
        <Modal :title="modalTilte" :id="modalId" @cancel="cancel" @shown="shown" :size="'extra-xl'" :showConfirmButton="false" :label-cancel-btn="'Close'">
            <orderShow :modal-id="showModalId" :id="showSelectedItemId"></orderShow>
            <div class="row mb-4">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <b-skeleton-wrapper :loading="$store.state.loading.loading">
                                <template #loading>
                                    <div class="row">
                                        <div class="col-6">
                                            <b-skeleton width="35%"></b-skeleton>
                                            <b-skeleton width="25%"></b-skeleton>
                                            <b-skeleton width="40%"></b-skeleton>
                                        </div>     
                                        <div class="col-6">
                                            <b-skeleton width="25%"></b-skeleton>
                                        </div>     
                                    </div> 
                                </template>
                                <div class="row">
                                    <div class="col-6">
                                        <div>
                                            <strong>Fecha de solicitud:</strong> {{ cardApplication?.created_at }}
                                        </div>                                               
                                        <div>
                                            <strong>Estado:</strong> <span v-html="status"></span>
                                        </div>
                                        <div>
                                            <strong>Cod. Único:</strong> {{ cardApplication?.unique_id }}
                                        </div>
                                    </div>     
                                    <div class="col-6">
                                        <div v-if="cardApplication?.order">
                                            <strong># de pedido:</strong> <a href="javascript:void(0)" @click="showViewModal(cardApplication?.order)">{{ cardApplication?.order?.id }} ({{ cardApplication?.order?.external_id }})</a> <span v-html="orderStatus"></span>
                                        </div>
                                    </div>     
                                </div> 
                            </b-skeleton-wrapper>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="card h-100">
                        <div class="card-body">                            
                            <h4 class="card-title mb-4">Datos personales</h4>
                            <b-skeleton-wrapper :loading="$store.state.loading.loading">
                                <template #loading>
                                    <b-skeleton-table
                                        :rows="7"
                                        :columns="2"
                                        :hide-header="true"
                                        :table-props="{ bordered: true, striped: true }"
                                    ></b-skeleton-table> 
                                </template>
                                <table class="table table-hover mb-0 table-centered table-nowrap">
                                    <tbody>
                                    <tr>
                                        <th class="text-right">Nombre</th>
                                        <td>{{ cardApplication?.name }}</td>
                                    </tr>
                                    <tr>
                                        <th class="text-right">Identificación</th>
                                        <td>{{ cardApplication?.identification }}</td>
                                    </tr>
                                    <tr>
                                        <th class="text-right">Género</th>
                                        <td>{{ gender }}</td>
                                    </tr>
                                    <tr>
                                        <th class="text-right">Email</th>
                                        <td>{{ cardApplication?.email }}</td>
                                    </tr>
                                    <tr>
                                        <th class="text-right">Celular</th>
                                        <td>{{ cardApplication?.mobile }}</td>
                                    </tr>
                                    <tr>
                                        <th class="text-right">Teléfono</th>
                                        <td>{{ cardApplication?.phone }}</td>
                                    </tr>
                                    <tr>
                                        <th class="text-right">Adjunto</th>
                                        <td>
                                            <a :href="cardApplication?.identification_media?.url" target="_blank">Descargar</a>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </b-skeleton-wrapper>                            
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="card h-100">
                        <div class="card-body">                            
                            <h4 class="card-title mb-4">Dirección</h4>
                            <b-skeleton-wrapper :loading="$store.state.loading.loading">
                                <template #loading>
                                    <b-skeleton-table
                                        :rows="5"
                                        :columns="2"
                                        :hide-header="true"
                                        :table-props="{ bordered: true, striped: true }"
                                    ></b-skeleton-table> 
                                </template>
                                <table class="table table-hover mb-0 table-centered table-nowrap">
                                    <tbody>
                                    <tr>
                                        <th class="text-right">Dirección</th>
                                        <td>{{ cardApplication?.address }}</td>
                                    </tr>
                                    <tr>
                                        <th class="text-right">Cod. Postal</th>
                                        <td>{{ cardApplication?.postcode }}</td>
                                    </tr>
                                    <tr>
                                        <th class="text-right">Población</th>
                                        <td>{{ cardApplication?.population?.name }}</td>
                                    </tr>
                                    <tr>
                                        <th class="text-right">Provincia</th>
                                        <td>{{ cardApplication?.province?.name }}</td>
                                    </tr>
                                    <tr>
                                        <th class="text-right">Comarca</th>
                                        <td>{{ cardApplication?.region?.name }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </b-skeleton-wrapper>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="card h-100">
                        <div class="card-body">                            
                            <h4 class="card-title mb-4">Envío</h4>
                            <b-skeleton-wrapper :loading="$store.state.loading.loading">
                                <template #loading>
                                    <b-skeleton-table
                                        :rows="3"
                                        :columns="2"
                                        :hide-header="true"
                                        :table-props="{ bordered: true, striped: true }"
                                    ></b-skeleton-table> 
                                </template>
                                <table class="table table-hover mb-0 table-centered table-nowrap">
                                    <tbody>
                                    <tr>
                                        <th class="text-right">Tipo de envío</th>
                                        <td>{{ shippingType }}</td>
                                    </tr>
                                    <template v-if="cardApplication?.card_shipping_type">
                                        <tr>
                                            <th class="text-right">Oficina</th>
                                            <td>{{ cardApplication?.card_shipping_type?.name }}</td>
                                        </tr>
                                        <tr>
                                            <th class="text-right">Información</th>
                                            <td>{{ cardApplication?.card_shipping_type?.description }}</td>
                                        </tr>
                                    </template>                                
                                    </tbody>
                                </table>
                            </b-skeleton-wrapper>
                            
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </div>    
</template>